/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

@import "./mat-table-v15";
@import "./header";
@import "./mat-button";
@import "./datepicker";
@import "./mat-form-field-v15";
@import "./mat-dialog-content-v15";
@import "./mat-select";
@import "./scrollbars";
@import "./mat-tab-group-v15";
@import "./mat-radio-button-v15";
@import "./angular_editor";
@import "./mat-paginator-v15";

html,
body {
  @apply bg-black font-ubuntu;
  height: 100%;
}
body {
  @apply bg-black flex flex-col font-ubuntu overflow-hidden w-screen h-screen relative m-0 p-0 #{!important};
}

.text-primary {
  color: $mc-primary !important;
}

ul {
  @apply block list-disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

ol {
  @apply block list-decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

img {
  display: inline !important;
}

.select-all.mat-mdc-checkbox {
  display: flex;
  padding-left: 5px;
  .mdc-form-field {
    width: 100%;
  }
  .mdc-checkbox {
    .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
      background-color: #3f51b5 !important;
      border-color: #3f51b5 !important;
    }

    .mdc-checkbox__native-control:enabled:indeterminate
      ~ .mdc-checkbox__background {
      background-color: transparent !important;
      border-color: grey !important;
    }
  }
  .mdc-form-field > label {
    @apply font-bold font-ubuntu;
    font-size: 15px;
  }
  // .mat-mdc-checkbox-ripple {
  //   position: initial !important;
  // }
  .mdc-checkbox__mixedmark {
    display: none;
  }
}

.select-all.mat-mdc-checkbox-checked {
  .mdc-form-field > label {
    color: #3f51b5 !important;
  }
}

.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option {
  font-size: inherit;
  line-height: 3em;
  height: 3em;
}
