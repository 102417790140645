/** Mat paginator **/

/*TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version.*/
mat-paginator.styledmobilitycompanyv15 {
  @apply w-full md:w-[65%];
  display: inline !important;

  background-color: transparent !important;
  color: white !important;
  text-align: right;
  white-space: nowrap;

  &.bgGray {
    @apply text-black;

    .mat-mdc-paginator-outer-container {
      @apply whitespace-nowrap flex-nowrap;
      .mat-mdc-paginator-container {
        @apply text-black flex-nowrap;

        .mat-mdc-paginator-page-size {
          .mat-mdc-paginator-page-size-label {
            @apply text-black;
          }

          .mat-mdc-form-field.mat-mdc-paginator-page-size-select {
            @apply border-none;
            .mat-mdc-text-field-wrapper {
              @apply text-black;

              .mat-mdc-form-field-flex {
                @apply border-none;
                .mat-mdc-form-field-infix {
                  .mat-mdc-select {
                    @apply text-black border-slate-500;

                    .mat-mdc-select-trigger {
                      @apply text-black;

                      .mat-mdc-select-value {
                        @apply text-black;
                      }

                      .mat-mdc-select-arrow {
                        @apply text-black;
                      }
                    }
                  }
                }

                .mdc-notched-outline__leading,
                .mdc-notched-outline__notch,
                .mdc-notched-outline__trailing {
                  border: none;
                }
              }
            }
          }
        }

        .mat-mdc-paginator-range-actions {
          @apply text-black;

          .mat-mdc-paginator-range-label {
            @apply text-black;
          }

          .mat-mdc-icon-button,
          .mat-mdc-paginator-navigation-first,
          .mat-mdc-paginator-navigation-previous,
          .mat-mdc-paginator-navigation-next,
          .mat-mdc-paginator-navigation-last {
            &[disabled] {
              //display: none;
              border: 1px solid rgba(122, 122, 122, 0.1);

              .mat-mdc-paginator-icon {
                fill: rgba(122, 122, 122, 0.1);
              }
            }
            border: 1px solid rgba(0, 0, 0, 0.4);

            .mat-mdc-paginator-icon {
              fill: rgba(0, 0, 0, 0.4);
            }
            @apply text-black;

            .mat-mdc-button-wrapper {
              @apply text-black;
            }

            .mat-mdc-icon-button svg,
            .mat-mdc-icon-button img {
              @apply text-black;
            }

            // .mat-mdc-paginator-icon {
            //   fill: rgba(255, 255, 255, 0.4);
            // }
          }

          button.mat-mdc-button-disabled {
            border: none;
          }
        }
      }
    }
  }

  .mat-mdc-paginator-outer-container {
    .mat-mdc-paginator-container {
      @apply flex justify-end flex-nowrap md:flex-wrap-reverse px-0 md:px-[8px];

      max-height: 50px !important;

      .mat-mdc-paginator-page-size {
        .mat-mdc-paginator-page-size-label {
          background-color: transparent;
          display: none;
        }

        .mat-mdc-form-field.mat-mdc-paginator-page-size-select {
          .mat-mdc-text-field-wrapper {
            padding-top: 4px;
            padding-bottom: 4px;

            .mat-mdc-form-field-flex {
              .mat-mdc-form-field-infix {
                .mat-mdc-select {
                  border: 1px solid rgba(255, 255, 255, 0.4);
                  //padding: 5px;
                  border-radius: 20px;
                  text-align: center;
                  font-size: 14px;
                  height: 28px;

                  .mat-mdc-select-trigger {
                    padding-left: 2px;
                    padding-right: 4px;

                    .mat-mdc-select-value {
                      color: rgba(255, 255, 255, 0.4);
                    }

                    .mat-mdc-select-arrow {
                      color: rgba(255, 255, 255, 0.4);
                    }
                  }
                }
              }

              .mdc-notched-outline__leading,
              .mdc-notched-outline__notch,
              .mdc-notched-outline__trailing {
                border: none;
              }
            }
          }
        }
      }

      .mat-mdc-paginator-range-actions {
        padding: 0;
        margin: 0;
        color: rgba(255, 255, 255, 0.4);
        align-items: center;

        .mat-mdc-paginator-range-label {
          // margin: 0;
          font-size: 12px;
        }

        .mat-mdc-icon-button,
        .mat-mdc-paginator-navigation-first,
        .mat-mdc-paginator-navigation-previous,
        .mat-mdc-paginator-navigation-next,
        .mat-mdc-paginator-navigation-last {
          &[disabled] {
            //display: none;
            border: 1px solid rgba(255, 255, 255, 0.1);

            .mat-mdc-paginator-icon {
              fill: rgba(255, 255, 255, 0.1);
            }
          }

          background-color: transparent;
          border: 1px solid rgba(255, 255, 255, 0.4);
          height: 28px;
          width: 28px;
          font-size: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.5rem;
          padding: 0 !important;

          .mat-mdc-button-wrapper {
            color: rgba(255, 255, 255, 0.4);
          }

          .mat-mdc-icon-button svg,
          .mat-mdc-icon-button img {
            width: 28px !important;
            color: rgba(255, 255, 255, 0.4);
            fill: white;
          }

          .mat-mdc-paginator-icon {
            fill: rgba(255, 255, 255, 0.4);
          }
        }

        button.mat-mdc-button-disabled {
          border: none;
        }
      }
    }
  }
}

/** /Mat paginator **/
