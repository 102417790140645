angular-editor.styled{

  angular-editor-toolbar button i {
    font-family: FontAwesome !important;
    color: #474d6d !important;

  }
  .angular-editor-button{
      border-radius: 25%;
    }
  .angular-editor-textarea {
      background-color: white !important;
      //margin:10px;
      //width: 95%;
      min-height: 300px !important;
      padding: 20px;
      border-radius: 20px !important;
      outline: none !important;
    }

  .angular-editor-toolbar {
      background: none !important;;
      border: none !important;
    }
}
