.mat-select-panel{
  background-color: white;
}

.mat-mdc-select-panel{
  .mat-mdc-option{
    @apply font-ubuntu font-semibold text-dark;
  }
}

.bgDark{
  .mat-mdc-select-value,
  .mat-mdc-select-arrow {
    color: white !important;
  }
}
//.mdc-list-item__primary-text{
//  color: black !important;
//}
mat-option.mat-mdc-option.mat-mdc-list-item{
  &.mat-mdc-option-active{
    color: black;
    span{
      color: black;
    }
  }
  .mdc-list-item:hover .mdc-list-item__primary-text{
    color: black;
  }
  .mdc-list-item--selected .mdc-list-item__primary-text, .mdc-list-item--activated .mdc-list-item__primary-text{
    color: black;
  }
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after{
  color: black;
}


