/**
 * RESPONSIVE SIZES
 */
$small-device-width: 768px;
$medium-device-width: 992px;
$large-device-width: 1200px;
/**
 * DIMENSIONS AND POSITIONS
 */
$mc-padding: 16px;
$dashboard-sidebar-width: 250px;
$dashboard-header-height: 100px;
$padding-top-dashboard-edit-panel: 39px;

$edit-default-footer-height: 60px;
/**
 * COLORS
 */
$mc-transparent: transparent;
$mainColor: #f2f2f2;

$mc-white: #ffffff;

$mc-primary: #c61932;

$mc-red: #c61932;

$mc-info: #f2f2f2;
$mc-danger:#f2f2f2;
$mc-success: #f2f2f2;
$mc-light:rgba(169, 169, 169, 0.9);
$mc-light-light: #f2f2f2;
$mc-light-dark: darken($mc-light, 3%);
$mc-grey: #f2f2f2;
$mc-dark: #f2f2f2;
//

$mainColor: #f2f2f2;
//
$mc-white: #ffffff;
//
//
//
$mc-red: #C61932;

//$inputWidth: 300px;
//
//$textfieldFontSize: 16px;
//$textfieldPaddingTop: 12px;
//$textfieldMarginTop: 8px;
//$textfieldMarginBottom: 8px;
//$textfieldInputPadding: 8px;
//$textfieldLabelFloatingFontSize: 12px;
//$textfiledLabelHighlightColor: color(secondary);
//$textfieldLabelBorderColor: #aaa;
//$textfieldLabelColor: #667;


$backgroundcolor: #000;

$text-black-color:#404040;
