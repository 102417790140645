
button[mdc-button].styled{
  background-color: rgba(255,255,255,0.9);
  color:#30373A;
  height: 36px;
  min-height: 36px;
  padding: 0 29px;
  border-radius: 18px;
  min-width: 120px;

  &.mat-accent{
    background-color: rgba(198, 25, 50, 0.7);
    color:white;
  }

  &.mat-accent:hover{
    background-color: #C61932;
    color:white;
  }

  &[disabled=true]{
    background-color: rgba(198, 25, 50, 0.7);
    color: rgba(245, 245, 245, 0.8);
  }

}

.mat-mdc-button.mat-primary {
    --mdc-text-button-label-text-color: black;
}


button.styled.dropdown{
  color:white;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.4);
  min-width: 70px;

  .mat-button-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-shrink: 1;
    overflow: hidden;
    >mat-icon{
      flex-grow: 0;
    }
    > span{
      text-overflow: ellipsis;
      flex-grow: 1;
      flex-shrink: 3;
      display: block;
      max-width: 100%;
      overflow: hidden;
    }
  }
}



//Icons
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: $mc-dark;
}




button {
  //border-radius: 18px !important;
  border: none;
  padding: 0 12px;
  min-height: inherit;
  font-size: 14.5px;
  cursor: pointer;
  text-transform: uppercase;
  &.btn-primary {
    background: $mc-primary;
    color: white;
    width: 150px;
    height: 30px;
    &:hover {
      background: darken($mc-primary, 5%);
      transition: all 0.5s;
    }
    &:active {
      background: darken($mc-primary, 10%);
    }
    &:disabled {
      background: transparentize($mc-primary, 0.5);
    }
  }
  &.btn-danger {
    background: $mc-danger;
    color: white;
    width: 150px;
    height: 30px;

    &:hover {
      background: darken($mc-danger, 5%);
      transition: all 0.5s;
    }
    &:active {
      background: darken($mc-danger, 10%);
    }
    &:disabled {
      background: transparentize($mc-danger, 0.5);
    }
  }
  &.btn-dark {
    background: $mc-dark;
    color: white;
    &:hover {
      background: darken($mc-dark, 5%);
      transition: all 0.5s;
    }
    &:active {
      background: darken($mc-dark, 10%);
      transition: all 0.5s;
    }
    &:disabled {
      background: transparentize($mc-dark, 0.5);
      transition: all 0.5s;
    }
  }
  &.btn-light {
    background: $mc-light;
    color: white;
    &:hover {
      background: darken($mc-light, 5%);
      transition: all 0.5s;
    }
    &:active {
      background: darken($mc-light, 10%);
      transition: all 0.5s;
    }
    &:disabled {
      background: transparentize($mc-light, 0.5);
      transition: all 0.5s;
    }
  }
}
