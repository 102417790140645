// Mat Radio Group
//mat-radio-group.styled {
//  .mat-radio-button {
//    border: 2px solid rgba(255, 255, 255, 0.4);
//    border-radius: 20px;
//    background-color: #000;
//    min-width: 130px;
//    min-height: 30px !important;
//
//    &.mat-radio-checked {
//      border: none;
//      background: #ffffff 0% 0% no-repeat padding-box;
//      min-height: 34px !important;
//      display: flex;
//      align-items: center;
//      .mat-radio-label {
//        font: normal normal bold 15px/17px Ubuntu;
//        color: #30373a;
//        text-transform: uppercase;
//        margin: 0;
//        padding: 0;
//      }
//    }
//
//    .mat-radio-label {
//      width: 100%;
//      height: 100%;
//      display: flex;
//      justify-content: center;
//      align-items: center;
//      font: normal normal medium 15px/17px Ubuntu;
//      color: rgba(255, 255, 255, 0.4);
//    }
//  }
//
//  .mat-radio-container,
//  .mat-radio-outer-circle,
//  .mat-radio-inner-circle {
//    width: 0;
//    height: 30px;
//    border-width: 0;
//    opacity: 0 !important;
//  }
//  .mat-radio-label-content {
//    padding-left: 0 !important;
//    text-transform: uppercase;
//  }
//}

// Mat Radio Group
mat-radio-group.styledmobilitycompanyv15 {
  .mat-mdc-radio-button {
    border: 2px solid rgba(255, 255, 255, 0.4);
    border-radius: 20px;
    background-color: #000;

    min-width: 130px;
    min-height: 34px !important;
    display: block;
    cursor: pointer;



    &.mat-radio-disabled {
      color: red;
      background-color: rgba(255, 255, 255, 0.4);
      .mat-radio-label-content {
        color: rgba(255, 255, 255, 0.2);
      }
    }

    &.mat-mdc-radio-checked {
      border: none;
      background: #ffffff 0 0 no-repeat padding-box;
      min-height: 34px !important;
      display: flex;
      align-items: center;
      .mdc-form-field {

        label {
          font: normal normal bold 15px/17px Ubuntu;
          color: #30373a;
          text-transform: uppercase;
          margin: 0;
          padding: 0;
        }
      }
      &.mat-radio-disabled {
        .mat-radio-label-content {
          color: rgba(0, 0, 0, 0.38);
        }
      }
    }

    .mdc-form-field{

      cursor: pointer;
      label{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font: normal normal medium 15px/17px Ubuntu;
        color: rgba(255, 255, 255, 0.4);
        text-transform: uppercase;
        cursor: pointer;
      }
    }

  }
  .mdc-form-field {
    @apply w-full h-full cursor-pointer;

    .mdc-radio {
      width: 0;
      padding: 0;

      .mdc-radio__background {
        display: none;
      }
    }
  }

  .mat-radio-container,
  .mat-radio-outer-circle,
  .mat-radio-inner-circle {
    width: 0;
    height: 30px;
    border-width: 0;
    opacity: 0 !important;
  }
  .mat-radio-label-content {
    padding-left: 0 !important;
  }
}
