
/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #81838a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  display: block !important;
  border-radius: 30px;
  background: rgb(126, 127, 133);
  transition: all 0.5s;
}
.mat-drawer-inner-container::-webkit-scrollbar {
  display: none !important;
}