@import 'src/variables';

h1.mat-mdc-dialog-title {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.mat-mdc-dialog-container {
  background-color: #e6e6e6;

  //padding: 10px !important;
  overflow: unset;

  border-radius: 30px !important;
  .mdc-dialog__surface{
    border-radius: 30px !important;
  }
  .mdc-dialog__container{
    width: 100%;
  }
  .mat-mdc-dialog-title {
    background-color: #e6e6e6;
    width: 100%;
    text-align: center;
    mat-icon {
      color: #30373a !important;
      font-size: 24px;
    }
    h1 {
      font-size: 24px;
      text-align: center;
      text-transform: uppercase;
      color: #30373a !important;
      margin-top: 0;
      padding: 0;
      width: 100%;
    }
    h2 {
      font-size: 24px;
      text-align: center;
      text-transform: uppercase;
      color: #30373a !important;
      margin-top: 0;
      padding: 0;
      width: 100%;
    }
  }
  .mat-mdc-dialog-content {
    margin: 15px 0 0 0;
    text-align: center;
    font-size: 19px;
    width: 100%;
    overflow: unset;
  }
  .mat-mdc-dialog-actions {
    //display: flex;
    margin-top: 15px;
    margin-bottom: 0;
    padding-bottom: 0;
    text-align: center;
    text-transform: uppercase;
    justify-content: center;

    .mat-raised-button {
      &:first-child {
        background-color: #bbbbbb;
      }
      width: 150px;
      text-transform: uppercase;
      // border-radius: 18px;
      border-radius: 40px;
      background-color: #c61932;

      color: #ffffff;
      font-size: 16px;
      line-height: 36px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      &[disabled] {
        background-color: rgba(198, 25, 50, 0.4);
        color: rgba(255, 255, 255, 0.4);
      }
      &[disabled]:hover {
        background-color: rgba(198, 25, 50, 0.4);
        color: rgba(255, 255, 255, 0.4);
      }
      &:hover {
        background-color: rgba(198, 25, 50, 0.8);
      }
      mat-icon {
        font-size: 16px;
        height: 18px;
      }
    }
  }
}


.withPadding{


  background-color: #e6e6e6 !important;
  border-radius: 30px;
  .mat-mdc-dialog-surface{
    padding: 16px;
    overflow-y: hidden;
  }
}


.mat-mdc-dialog-container {
  --mdc-dialog-container-color: #e6e6e6;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
  --mdc-dialog-subhead-font: Ubuntu, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Ubuntu, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}
