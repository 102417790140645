
/// Stili per il calendario
.mat-calendar-body-selected {
    background-color: #30373A;
    color: #fff;
}
.mat-calendar-body-in-range::before {
    background: lighten($color: #30373A, $amount: 65%);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: lighten($color: #30373A, $amount: 35%);
    color: #fff;
}

/// /Stili per il calendario