// HEADER FOR NEW PAGE
.header {
  @apply flex flex-col items-center justify-start md:flex-row md:items-center md:justify-between border-0 border-b-2 border-solid border-mc-uniform w-full pb-4 my-4 mx-0;



  > div {
    width: 100%;
    height: 100%;
  }

  &.withoutBorder {
    @apply border-0 pb-0;

  }

  h2 {
    @apply m-0 font-bold text-white uppercase text-2xl;
  }

  .left {
    @apply flex flex-col items-center justify-start md:flex-row md:items-center md:justify-between gap-2 flex-grow-0;

  }

  .center {
    @apply flex flex-row items-center justify-center flex-grow;

  }
  .right {
    @apply flex flex-row items-center justify-end flex-grow-0 gap-2 mt-5 md:mt-0;



  }
}

// / HEADER FOR NEW PAGE
