@import 'src/variables';

// Mat Table
mat-table.styledmobilitycompanyv15 {
  background-color: transparent;
  font-family: Ubuntu, sans-serif !important;
  &.forDialog {
    .mat-mdc-cell {
      color: black !important;
    }

    .mat-divider-vertical {
      background-color: black !important;
    }

    .mat-mdc-header-row {
      background-color: #e6e6e6;
      border-top: 1px solid #333333;
      border-bottom: 1px solid #333333;
      //.mat-mdc-header-cell {
      //  color: rgba(255, 255, 255, 0.4);
      //  text-transform: uppercase;
      //  font-family: Ubuntu, sans-serif !important;
      //  font-size: 14px;
      //  border-top: none;
      //  border-bottom: none;
      //}
    }

    mat-row.mat-mdc-row {
      border-bottom-color: rgba(0, 0, 0, 0.4);
      mat-cell.mat-mdc-cell {
        color: black;
      }
    }

    .actions {
      .action {
        @apply text-black border-black;
        mat-icon {
          color: rgba(0, 0, 0, 0.4);
          font-size: 20px;

          &:hover {
            color: rgba(0, 0, 0, 0.9);
          }
        }
        &:not(:last-child) {
          border-right: 1px solid rgba(0, 0, 0, 0.4);
        }
      }
    }
  }

  .mat-mdc-header-row {
    @apply bg-black;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    .mat-mdc-header-cell {
      color: rgba(255, 255, 255, 0.4);
      text-transform: uppercase;
      font-family: Ubuntu, sans-serif !important;
      font-size: 14px;
      border-top: none;
      border-bottom: none;
    }
  }
  mat-row.mat-mdc-row {
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    mat-cell.mat-mdc-cell {
      color: white;
      font-size: 15px;
      font-family: Ubuntu, sans-serif !important;
    }
  }

  .actions {
    @apply w-full flex flex-row items-center justify-end;
    .action {
      &:last-child {
        border-right: none;
      }
      &:not(:last-child) {
        border-right: 1px solid rgba(255, 255, 255, 0.4);
      }
    }
  }
  .action {
    @apply bg-transparent text-slate-500 px-3;

    mat-icon {
      width: 20px;
      height: 20px;
      font-size: 20px;
      cursor: pointer;
    }
    &:hover {
      color: white;
      transition: all 0.5s;
    }

    &.active {
      color: white;
      &:hover {
        color: rgba(255, 255, 255, 0.4);
        transition: all 0.5s;
      }
    }
  }

  &.bgWhite {
    background-color: transparent;
    .mat-mdc-header-row {
      border-top: 1px solid lighten($color: $text-black-color, $amount: 30%);
      border-bottom: 1px solid lighten($color: $text-black-color, $amount: 30%);
      .mat-mdc-header-cell {
        color: lighten($color: $text-black-color, $amount: 30%);
      }
    }
    mat-row.mat-mdc-row {
      background-color: transparent;
      border-bottom-color: lighten($color: $text-black-color, $amount: 30%);
      border-bottom-width: 1px;

      mat-cell.mat-mdc-cell {
        color: $text-black-color;
        &.mat-column-actions {
          color: $text-black-color;
          .action {
            color: $text-black-color;
          }

          .mat-icon {
            color: $text-black-color;
          }
        }
      }
    }
  }
}
// /Mat Table
mat-cell:last-of-type,
mat-header-cell:last-of-type,
mat-footer-cell:last-of-type {
  padding-right: 0 !important;
}

table.styledmobilitycompanyv15 {
  @apply bg-transparent w-full max-h-full h-fit;

  .mat-mdc-table-sticky{
    @apply bg-black;
  }
  .mat-mdc-table-sticky.mat-mdc-table-sticky-border-elem-right{
    @apply bg-black;
  }
  tbody {

    tr {
      @apply hover:bg-gray-900 hover:cursor-pointer #{!important};
      &:hover{
        td{
          @apply bg-gray-900 cursor-pointer #{!important};
        }
      }
      &.expandable-detail-row{
        min-height: unset;
        max-height: unset;
        height: auto;
        td > div{
          min-height: unset;
          max-height: unset;
          height: auto  ;
        }
      }
      &:first-child {
        vertical-align: top;
      }
    }
  }
  th {
    @apply border-y border-y-gray-500 border-solid border-x-0 text-gray-500 font-ubuntu text-base;
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    background-color: black !important;
    div{
      @apply overflow-hidden;
    }
    p {
      @apply overflow-hidden truncate uppercase;
      &.actions{
        text-align: right;
      }
    }
  }
  td {
    @apply text-white border-b border-mc-uniform border-opacity-40;
    >p{
      @apply font-ubuntu overflow-hidden text-ellipsis whitespace-nowrap;
    }
    >div{
      @apply flex flex-row items-center ;
      max-height: 52px;
      height: 52px;
    }
    div.actions {
      @apply bg-transparent h-full flex flex-row items-center justify-end;
      button.action {
        @apply text-black  bg-transparent  ;
        mat-icon {
          @apply text-mc-uniform;

          font-size: 20px;

          &:hover {
            @apply text-white transition-all duration-500;
          }
        }
        &:not(:last-child) {
          @apply border-solid border-t-0 border-r border-l-0 border-b-0 border-gray-500 ;

        }
      }
    }
  }
}

.bottomTable {
  @apply flex flex-col items-center justify-center md:flex-row md:items-center md:justify-between overflow-hidden;
}
